import { useTranslate } from "../../../../customHooks";
import { Field } from "react-final-form";
import { PANWDSInput } from "../../../../components/PANWDSElements";
import {
    composeValidators,
    isAlphaNumeric,
    isRequired,
    maxElements,
    maxLength,
} from "../../../../utils/validate";
import { FormControl, Grid } from "@material-ui/core";
import * as React from "react";
import { PANChip, Row } from "../../../../components/FormElements";

const Index = () => {
    const translate = useTranslate();
    return (
        <>
            <Row>
                <Grid item xs={12} sm={6} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="FirewallName"
                            // @ts-ignore
                            component={PANWDSInput}
                            title={translate(
                                `resources.firewalls.fields.FirewallName`
                            )}
                            required
                            validate={composeValidators(
                                isAlphaNumeric,
                                isRequired,
                                maxLength(128)
                            )}
                            dataMetrics="cloudngfw-firewall-create-name"
                        />
                    </FormControl>
                </Grid>
            </Row>
            <Row>
                <Grid item xs={12} sm={6} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="Description"
                            // @ts-ignore
                            component={PANWDSInput}
                            title={translate(
                                `resources.firewalls.fields.Description`
                            )}
                            validate={maxLength(512)}
                            dataMetrics="cloudngfw-firewall-create-description"
                        />
                    </FormControl>
                </Grid>
            </Row>
            <Row>
                <Grid item xs={12} sm={6} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="Tags"
                            // @ts-ignore
                            component={PANChip}
                            options={[]}
                            addNew
                            addNewLabel="Add New"
                            label={`Tags`}
                            validate={maxElements(200)}
                            addDirectly
                        />
                    </FormControl>
                </Grid>
            </Row>
        </>
    );
};

export default Index;
