import {Row} from "../../../../components/FormElements";
import {FormControl, Grid} from "@material-ui/core";
import {Field, useFormState, useForm} from "react-final-form";
import {
    PANWDSCheckboxField,
    PANWDSChipInput,
    PANWDSSelect,
    PANWDSSelectWithSearch
} from "../../../../components/PANWDSElements";
import {toast} from "../../../../components";
import {usePermissions, useTranslate} from "../../../../customHooks";
import {useEffect, useState} from "react";
import {dataProvider} from "../../../../dataProvider";
import * as DataModels from "../../../../api/FwaasDataModels";
import * as DataTypes from "../../../../api/FwaasDataTypes";
import {isEmpty} from "lodash";
import * as React from "react";
import {FirewallEditComponent} from "../index";

const Index = ({record}: { record: any }) => {
    const translate = useTranslate();
    const {values} = useFormState();
    const { permissions } = usePermissions();

    const [availabilityZoneChoices, setAvailabilityZoneChoices] = useState<any[] | undefined>(undefined);
    const [subnetChoices, setSubnetChoices] = useState<any[] | undefined>(undefined);
    const [vpcSubnetData, setVpcSubnetData] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (record && record.Firewall) {
            if (record?.Firewall && record?.Firewall?.EndpointMode === "ServiceManaged") {
                dataProvider.describe("xaccountroles", '', {
                    AccountId: record?.Firewall.AccountId,
                    DescribeAccount: true,
                    VpcId: record?.Firewall.VpcId
                })
                    .then((response: any) => {
                        if (response.data) {
                            //@ts-ignore
                            if (response.data?.VpcInformations) {
                                //@ts-ignore
                                let vpcData = response.data?.VpcInformations;
                                setVpcSubnetData(({...vpcSubnetData, [vpcData[0].VpcId]: vpcData[0].Subnets}));
                                // setSubnetChoices(vpcData[0].SubnetInformations.map((subnet: any) => ({
                                //     id: {"SubnetId": subnet['SubnetId']},
                                //     name: (subnet['SubnetName']) ? `${subnet['SubnetId']} (${subnet['SubnetName']})` : subnet['SubnetId']
                                // })));
                                setSubnetChoices(vpcData[0].SubnetInformations.map((subnet: any) => ({
                                    value: subnet['SubnetId'],
                                    text: (subnet['SubnetName']) ? `${subnet['SubnetId']} (${subnet['SubnetName']})` : subnet['SubnetId']
                                })));
                                return;
                            }
                        } else {
                            toast.error(response?.error, {toastId: "xaccountroles-describe"});
                        }
                        setVpcSubnetData({});
                        setSubnetChoices([]);
                    })
                    .catch((e: any) => {
                        toast.error(e?.error, {toastId: "xaccountroles-describe"});
                    });
            }
            if (record?.Firewall && record?.Firewall?.EndpointMode === "CustomerManaged") {
                describeAccount({AccountId: record?.Firewall.AccountId})
                    .then((r) => {
                        if (r.data) {
                            //@ts-ignore
                            if (r.data?.AvailabilityZones) {
                                //@ts-ignore
                                let availabilityZoneData = r.data?.AvailabilityZones;
                                let availabilityZoneIds = r.data?.AvailabilityZoneIds;
                                // setAvailabilityZoneChoices(availabilityZoneData.map((zone: any, index: number) => ({
                                //     id: {AvailabilityZone: (availabilityZoneIds) ? `${zone} (${availabilityZoneIds[index]})` : zone},
                                //     name: (availabilityZoneIds) ? `${zone} (${availabilityZoneIds[index]})` : zone
                                // })));
                                setAvailabilityZoneChoices(availabilityZoneData.map((zone: any, index: number) => ({
                                    value: (availabilityZoneIds) ? `${zone} (${availabilityZoneIds[index]})` : zone,
                                    text: (availabilityZoneIds) ? `${zone} (${availabilityZoneIds[index]})` : zone
                                })));
                            }
                            return;
                        } else {
                            toast.error(r?.error, {toastId: "account-describe"});
                        }
                        setAvailabilityZoneChoices([]);

                    })
                    .catch((e: any) => {
                        toast.error(e?.error, {toastId: "account-describe"});
                    });
            }
        }
    }, [record]);

    const describeAccount = async (request: DataModels.DescribeLinkAccountRequest): Promise<DataTypes.IFwaasApiResponse> => {
        return dataProvider.describe("xaccountroles", '', {AccountId: request.AccountId, DescribeAccount: true})
            .then((response: any) => {
                let ret = {};
                if (response?.data) {
                    //ret.data = new DataTypes.Account().fromJSON(responseObj.Response);
                    ret = response;
                } else {
                    toast.error(response?.error, {toastId: "xaccountroles-describe"});
                }
                return ret;
            })
            .catch((e: any) => {
                toast.error(e?.error, {toastId: "xaccountroles-describe"});
            });
    }

    const handleAZValueFormat= (v: any) => {
        if (typeof v === 'string') {
          return v;
        }
        return (v?.AvailabilityZoneId) ? `${v.AvailabilityZone} (${v.AvailabilityZoneId})` : v.AvailabilityZone;
    }

    const handleSubnetValueFormat= (v: any) => {
        if (typeof v === 'string') {
            return v;
        }
        return v['SubnetId'];
    }

    return (
        <>
            {values?.Firewall?.EndpointMode === "CustomerManaged" &&
                <>
                    <Row>
                        <Grid item xs={6} style={{padding: '0 0 1rem 0'}}>
                            <FormControl fullWidth>
                                <Field
                                    name="Firewall.MultiVpcEnable"
                                    // @ts-ignore
                                    component={PANWDSCheckboxField}
                                    defaultValue={false}
                                    label={translate(`resources.firewalls.fields.Shared`)}
                                    type="checkbox"
                                    disabled={!permissions?.AssociateSubnetMappings}
                                    dataMetrics={"cloudngfw-firewall-edit-multivpcenabled"}
                                />
                            </FormControl>
                        </Grid>
                    </Row>
                    <Row>
                        <Grid item xs={12} sm={6} className="bottomPadding">
                            <FormControl fullWidth>
                                <Field
                                    name="Firewall.AccountId"
                                    readOnly
                                    // @ts-ignore
                                    component={PANWDSSelect}
                                    items={(record?.Firewall?.AccountId) ? [{ text: record?.Firewall?.AccountId, value: record?.Firewall?.AccountId }] : []}
                                    title={translate(`resources.firewalls.fields.AccountId`)}
                                    required={false}
                                    loading={isEmpty(record)}
                                    dataMetrics={"cloudngfw-firewall-edit-account-id"}
                                    placeholder={'Select an account'}
                                />
                            </FormControl>
                        </Grid>
                    </Row>
                    <Row>
                        <Grid item xs={12} sm={6} className="bottomPadding">
                            <FormControl fullWidth>
                                <Field
                                    name="Firewall.VpcId"
                                    readOnly
                                    // @ts-ignore
                                    component={PANWDSSelectWithSearch}
                                    items={(record?.Firewall?.VpcId) ? [{ text: record?.Firewall?.VpcName ? `${record?.Firewall?.VpcId} (${record?.Firewall?.VpcName})` : record?.Firewall?.VpcId, value: record?.Firewall?.VpcId }] : []}
                                    title={translate(`resources.firewalls.fields.VpcId`)}
                                    required={false}
                                    loading={isEmpty(record)}
                                    dataMetrics={"cloudngfw-firewall-edit-vpc"}
                                />
                            </FormControl>
                        </Grid>
                    </Row>
                    <Row>
                        <Grid item xs={12} sm={6} className="bottomPadding">
                            <FormControl fullWidth>
                                {/*<Field*/}
                                {/*    name="Firewall.SubnetMappings"*/}
                                {/*    format={(value: any) => value?.map((v: any) => v['AvailabilityZone'])}*/}
                                {/*    row*/}
                                {/*    // @ts-ignore*/}
                                {/*    component={PANWDSChipInput}*/}
                                {/*    title={translate(`resources.firewalls.fields.AvailabilityZone`)}*/}
                                {/*    items={(availabilityZoneChoices || []).map(sc => (sc.id['AvailabilityZone']))}*/}
                                {/*    loading={availabilityZoneChoices === undefined}*/}
                                {/*    disabled={!permissions?.AssociateSubnetMappings || values?.FirewallUpdating}*/}
                                {/*    disabledTags={!permissions?.DisassociateSubnetMappings}*/}
                                {/*    dataMetrics={"cloudngfw-firewall-edit-availability-zone"}*/}
                                {/*    enableArrayInput*/}
                                {/*    formatter={(value: any) => ({AvailabilityZone: value})}*/}
                                {/*    menuStyle={{width: '12rem'}}*/}
                                {/*/>*/}
                                <Field
                                    name="Firewall.SubnetMappings"
                                    format={(value) => value?.map(handleAZValueFormat)}
                                    row
                                    // @ts-ignore
                                    component={PANWDSSelectWithSearch}
                                    title={translate(`resources.firewalls.fields.AvailabilityZone`)}
                                    items={availabilityZoneChoices}
                                    loading={availabilityZoneChoices === undefined}
                                    disabled={!permissions?.AssociateSubnetMappings || values?.FirewallUpdating}
                                    disabledTags={!permissions?.DisassociateSubnetMappings}
                                    dataMetrics={"cloudngfw-firewall-edit-availability-zone"}
                                    enableArrayInput
                                    enableMultiSelect
                                    formatter={(value: any) => ({AvailabilityZone: value})}
                                />
                            </FormControl>
                        </Grid>
                    </Row>
                </>
            }
            {values?.Firewall?.EndpointMode === "ServiceManaged" &&
                <Row>
                    <Grid item xs={12} sm={6} className="bottomPadding">
                        <FormControl fullWidth>
                            {/*<Field*/}
                            {/*    name="Firewall.SubnetMappings"*/}
                            {/*    format={(value: any) => value.map((v: any) => v['SubnetId'])}*/}
                            {/*    row*/}
                            {/*    // @ts-ignore*/}
                            {/*    component={PANWDSChipInput}*/}
                            {/*    title={translate(`resources.firewalls.fields.Subnet`)}*/}
                            {/*    items={(subnetChoices || []).map(sc => (sc.id['SubnetId']))}*/}
                            {/*    loading={subnetChoices === undefined}*/}
                            {/*    dataMetrics={"cloudngfw-firewall-edit-subnet"}*/}
                            {/*    disabled={!permissions?.AssociateSubnetMappings || values?.FirewallUpdating}*/}
                            {/*    disabledTags={!permissions?.DisassociateSubnetMappings}*/}
                            {/*    enableArrayInput*/}
                            {/*    formatter={(value: any) => ({SubnetId: value})}*/}
                            {/*    menuStyle={{width: '12rem'}}*/}
                            {/*/>*/}
                            <Field
                                name="Firewall.SubnetMappings"
                                format={(value) => value?.map(handleSubnetValueFormat)}
                                row
                                // @ts-ignore
                                component={PANWDSSelectWithSearch}
                                title={translate(`resources.firewalls.fields.Subnet`)}
                                items={subnetChoices}
                                loading={subnetChoices === undefined}
                                dataMetrics={"cloudngfw-firewall-edit-subnet"}
                                disabled={!permissions?.AssociateSubnetMappings || values?.FirewallUpdating}
                                disabledTags={!permissions?.DisassociateSubnetMappings}
                                enableArrayInput
                                enableMultiSelect
                                formatter={(value: any) => ({SubnetId: value})}
                            />
                        </FormControl>
                    </Grid>
                </Row>
            }
            <FirewallEditComponent.FirewallSettingsComponent.EndpointSettings />
        </>
    );
};
export default Index;